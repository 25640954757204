<template>
  <div class="widget-container pt-4 pb-16">
    <div v-html="widgetHtml">loading</div>
  </div>
</template>

<script>
import YuccaElements from "@yucca/wc-yucca-widgets/yucca-elements";

export default {
  name: "YuccaWidget",
  props: {
    html: { type: String, required: true },
    script: { type: String, required: true },
  },
  computed: {
    widgetHtml() {
      var txt = document.createElement("textarea");
      txt.innerHTML = decodeURI(encodeURI(this.html).replaceAll("%C2%A0", "%20"));
      return txt.value;
    },
    widgetScript() {
      var txt = document.createElement("textarea");
      txt.innerHTML = this.script.replaceAll("&quot;", "\"");
      return txt.value;
    },
  },
  async mounted() {
    const ysc = document.getElementById("yuccaScript");
    if (!ysc) {
      let yuccaScript = document.createElement("script");
      yuccaScript.setAttribute("id", "yuccaScript");
      yuccaScript.text = YuccaElements;
    }

    try {
      var setWidgetParams = new Function(
        this.widgetScript.replaceAll("\u200b", "")
      );
      await setWidgetParams();
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss">
@import "~@yucca/wc-yucca-widgets/assets/styles/c3.min.css";
@import "~@yucca/wc-yucca-widgets/assets/styles/yucca-widget.css";
.widget-container {
  font-size: 16px;
}
</style>
